/* Splash Page CSS  */
.splash-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: url("../assets/splash.png") no-repeat 100% 50% / cover;
  padding: 40px 0;
  height: 100%;
}

.splash-content h1 {
  margin-top: 48px;
}
.splash-content h2 {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .splash-logo {
    width: 60%;
  }
}
