.journey-container {
  height: 100%;
}

.container {
  display: flex;
}
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .logo {
    width: 80%;
  }
}

.customer_detail {
  flex: 0.6;
}
.tracking_info {
  flex: 0.4;
  padding: 25px;
}

/* STATUS CONTAINER START */
.status-container {
  margin: 20px;
  border: 1px solid #d8d3d3;
  background: #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
@media (min-width: 1000px) {
  .status-container {
    margin: 50px 10%;
  }
  .tracking_info {
    padding-right: 100px;
  }
}

.status-container h1 {
  color: var(--red);
}
.status-container .text-center {
  border-bottom: 1px solid var(--brown);
  padding-bottom: 20px;
  margin-bottom: 10px;
}

/* STATUS CONTAINER END */

/*  CONSUMER DETAIL START */
.consumer-detail .d-flex {
  margin: 5px 0;
}
.consumer-detail h4:first-child {
  font-weight: 500;
}
.consumer-detail h4 {
  font-weight: 300;
  flex: 1;
  font-size: 12px;
  word-break: break-all;
}
/*  CONSUMER DETAIL END */

/* TIMLINE START */
.tracking-dot {
  width: 25px;
  height: 25px;
  background-color: var(--green);
  border-radius: 15px;
  padding: 2px;
}
.tracking-dot-active {
  border: 3px solid var(--green-border);
}
.tracking-inactive {
  background-color: var(--white);
  border: 3px solid var(--gray);
}
.tracking-dot-error {
  background-color: var(--light-red);
  border: 3px solid var(--red);
  margin-left: 2px;
}
.tracking-dot-warning {
  background-color: var(--light-yellow);
  border: 3px solid var(--yellow);
}
.ant-timeline-item-label {
  width: calc(50% - 20px) !important;
  font-size: 20px;
  inset-block-start: -10px !important;
}
.ant-timeline-item-content {
  font-size: 20px;
  font-weight: 500;
  inset-block-start: -10px !important;
}
@media (max-width: 768px) {
  .ant-timeline-item-label {
    width: calc(50% - 20px) !important;
    font-size: 14px;
    inset-block-start: -6px !important;
  }
  .ant-timeline-item-content {
    font-size: 16px;
    font-weight: 500;
    inset-block-start: -6px !important;
  }
}

.reached .ant-timeline-item-content {
  opacity: 0.5;
}
.reached .ant-timeline-item-label {
  opacity: 0.5;
}

.tracker-card-container {
  border-left: 4px solid var(--light-green);
  background: var(--whiteGray);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.tracker-card-container.error {
  border-left: 4px solid var(--red);
}

.tracker-card-container.warning {
  border-left: 4px solid var(--yellow);
}
.tracker-card-container h4,
.tracker-card-container h5 {
  color: var(--brown);
  font-weight: 300;
}
.tracker-card-container h5 {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* TIMLINE END */
.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
.error-container h1 {
  margin-bottom: 40px;
  margin-top: 20px;
}
.error-container h2 {
  line-height: 2;
}
