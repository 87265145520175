.sales_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
}

.divider {
  border: 0.5px solid var(--divider);
  width: 100%;
  margin: 10px 0;
}

.sales_info {
  max-width: 600px;
  overflow: auto;
}

.sales_body {
  padding: 30px 20px;
}

.sales_container a {
  text-decoration: underline;
  font-weight: 700;
}

.sales_ack span {
  cursor: pointer;
  color: var(--red);
  font-weight: 700;
  text-decoration: underline;
}

.sales_thanks {
  box-shadow: 0px 2px 10px 0px #00000040;
  border-radius: 10px;
  z-index: 10000;
  background-color: #fff;
}

.sales_ack_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 0;
}

.sales_thanks_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.sales_ack_title {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
}
