body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.d-flex {
  display: flex;
}
.d-column {
  flex-direction: column;
}
.h-center {
  justify-content: center;
}
.v-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.text-center {
  text-align: center;
}

.h-full {
  height: 100%;
}

.mv-10 {
  margin: 10px 0;
}
.mv-20 {
  margin: 20px 0;
}
.mv-30 {
  margin: 30px 0;
}
.mv-50 {
  margin: 50px 0;
}
.mv-40 {
  margin: 40px 0;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}
