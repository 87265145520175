/*  Footer START */
.footer-container {
  padding: 20px;
  background-color: var(--gray);
}
.footer-container h5 {
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.2px;
}

.footer-container span {
  text-decoration-line: underline;
}
.footer-container a {
  color: #000;
}
/*  Footer END */
