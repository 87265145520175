h1 {
  font-size: 38px;
}
h2 {
  font-size: 24px;
}
h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
.fw-700 {
  font-weight: 700;
}
.fw-500 {
  font-weight: 500;
}
.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px; /* Adjust the font size for smaller screens */
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
}
