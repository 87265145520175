:root {
  --red: #e21e2d;
  --blue: #0572d6;
  --gray: #eeeeee;
  --green: #9fed93;
  --light-green: #019c2c;
  --white: #ffffff;
  --green-border: #73b786;
  --whiteGray: #f5f5f5;
  --brown: #545454;
  --light-red: #fcc3c3;
  --yellow: #efdc2c;
  --light-yellow: #faf5c2;
  --divider: #b3b3b3;
}

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  overflow: auto;
  flex: 1;
}

a {
  color: var(--red);
}
