.loading-container {
  display: block;
  text-align: center;
}

.loading {
  pointer-events: none;
  margin-top: 26px;
}
@media (prefers-reduced-motion: no-preference) {
  .loading {
    animation: App-logo-spin infinite 2s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
